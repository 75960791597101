<!-- notFound -->
<template>
  <div class="content">
      <div class="notFound">
          找不到页面，请输入正确的网址！
          <!-- <span @click="home">点击回到首页</span> -->
      </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
  data () {
    return {
    };
  },
  created(){},
  methods: {
    home(){
      this.$router.push({
        name: 'Home',
        params:{
          code: 156
        }
      })
    }
  }
}

</script>
<style lang='less' scoped>
.notFound{
    width: 100vw;
    height: 100vh;
    background: #488dd3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #fff;
    span{
      color: blue;
      cursor: pointer;
    }
}
</style>